import i18next from "i18next";
import urls from "./urls";
import {makeId} from "./utils";

export function displayError(text = 'Error') {
    displayAlert(text, 'alert-danger')
}

export function displaySuccess(text = 'Success') {
    displayAlert(text, 'alert-success')
}

export function displayAlert(text, className) {
    const alertContainerHtml = '<div class="d-flex justify-content-center align-items-center vw-100 position-fixed alert-container custom-alert-container" style="z-index: 19999;"></div>'
    const alertHtml = `<div class="alert ${className} custom-alert alert-dismissible fade show" role="alert">
  <span class="display-alert-text">${text}</span>
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>`

    let alertContainer = $(alertContainerHtml);
    alertContainer.append($(alertHtml));
    $('#content').prepend(alertContainer)


    setTimeout(function () {

        $(`.${className}`).alert('close');
        $('.alert-container').remove();

    }, 1700);
}


export function handleFetchError(error) {
    console.error('Error fetching data:', error);
    if (typeof error === 'object') {
        // Access the object directly and display or handle as needed.
        // E.g., displayError(error.message);
    } else {
        displayError(error.toString());
    }
}


export function bindToolTips(selector = '.answer-tooltip') {
    new bootstrap.Tooltip(document.body, {
        selector: selector,
        delay: {
            show: 500,
            hide: 100
        },
        template: customTemplate,
    });
}

export function initializeTooltip(element) {
    new bootstrap.Tooltip(element, {
        delay: {
            show: 500,
            hide: 100
        },
        template: customTemplate,
    });
}

export const customTemplate = `<div class="tooltip ogo-tooltip" role="tooltip">
  <div class="tooltip-arrow ogo-tooltip-arrow"></div>
  <div class="tooltip-inner ogo-tooltip-inner">
    <span id="tooltip-content"></span>
  </div>
</div>`


export function noAccountsHtml() {

    const noAccountsTitleTran = i18next.t('common:dom.noAccountsTitle') // Вы пока не добавили аккаунтов OLX.
    const noAccountsTextTran = i18next.t('common:dom.noAccountsText') // Добавть свой первый аккаунт прямо сейчас.
    const noAccountsButtonTran = i18next.t('common:dom.noAccountsButton') // Добавить новый
    return genAccountWarningModalHtml(noAccountsTitleTran, noAccountsTextTran, noAccountsButtonTran)
}


function genAccountWarningHtml(title, text, buttonText, url) {
    return `<div class="container-xl"><div class="mt-5 mb-5 text-center">
<div class="d-flex justify-content-center mb-3">
<h2 class="col12 col-sm-10 col-md-8">${title}</h2>
</div>
            
            <div class="d-flex mb-3 justify-content-center">
            <p class="col12 col-sm-8 col-md-6 ">${text}</p>
            </div>
            <a href="${url}" class="filled-button">${buttonText}</a>
        </div></div>`
}


function genAccountWarningModalHtml(title, text, buttonText) {
    return `<div class="container-xl"><div class="mt-5 mb-5 text-center">
<div class="d-flex justify-content-center mb-3">
<h2 class="col12 col-sm-10 col-md-8">${title}</h2>
</div>
            
            <div class="d-flex mb-3 justify-content-center">
            <p class="col12 col-sm-8 col-md-6 ">${text}</p>
            </div>
            <a href="#" class="filled-button" data-bs-toggle="modal" data-bs-target="#addAccountModal">${buttonText}</a>
        </div></div>`
}


export function expiredAccountsHtml() {

    const title = i18next.t('common:dom.expiredAccountsTitle') // У вас нет активных аккаунтов OLX.
    const text = i18next.t('common:dom.expiredAccountsText') // Но у вас есть один или несколько аккаунтов с истекшим сроком подключения. Для продолжения использования сервиса, пожалуйста, обновите подключение хотя бы одного из них или добавьте новый аккаунт.
    const buttonText = i18next.t('common:dom.expiredAccountsButton') // Обновить или добавить новый аккаунт
    return genAccountWarningHtml(title, text, buttonText, urls.accountsPage)
}

export function inactiveAccountsHtml() {
    const title = i18next.t('common:dom.inactiveAccountsTitle') // У вас нет активных аккаунтов OLX.
    const text = i18next.t('common:dom.inactiveAccountsText') // Но у вас есть один или несколько выключенных аккаунтов. Для продолжения использования сервиса, пожалуйста, включите хотя бы один из них или добавьте новый аккаунт.
    const buttonText = i18next.t('common:dom.inactiveAccountsButton') // Включить или добавить новый аккаунт
    return genAccountWarningHtml(title, text, buttonText, urls.accountsPage)
}

export function expiredAndInactiveAccountsHtml() {
    const title = i18next.t('common:dom.expiredAndInactiveAccountsTitle') // Вы пока не добавили аккаунтов OLX.
    const text = i18next.t('common:dom.expiredAndInactiveAccountsText') // У вас есть аккаунты OLX, которые в настоящее время отключены или имеют истекший срок подключения. Для восстановления активности и продолжения использования наших сервисов, пожалуйста, обновите подключение или включите хотя бы один из ваших аккаунтов.
    const buttonText = i18next.t('common:dom.expiredAndInactiveAccountsButton') // Обновить или включить аккаунты
    return genAccountWarningHtml(title, text, buttonText, urls.accountsPage)
}


export function hideModal(modalId) {
    const myModalEl = document.getElementById(modalId);
    const modal = bootstrap.Modal.getInstance(myModalEl);
    modal.hide();
}


export function makeAppHeaderAbsolute() {
    let appHeader = $('.app-header')
    appHeader.css('position', 'absolute')
    appHeader.addClass('app-header-absolute')
}


export function makeAppHeaderSticky() {
    let appHeader = $('.app-header')
    appHeader.addClass('app-header-sticky')
}


export function appendScrollButtons() {
    const body = $('body');
    body.append('<div id="scroll-up" class="scroll-btn"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" viewBox="0 0 24 24" style="width: 39px" xml:space="preserve"><g><path d="M16.2 10.2c-.2.2-.5.3-.7.3s-.5-.1-.7-.3L13 8.4V21c0 .5-.5 1-1 1s-1-.5-1-1V8.4l-1.8 1.8c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l3.5-3.5c.4-.4 1-.4 1.4 0l3.5 3.5c.4.4.4 1 0 1.4zM20 4H4c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1z" fill="currentColor" opacity="1" data-original="#000000"/></g></svg></div>');
    body.append('<div id="scroll-down" class="scroll-btn"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" viewBox="0 0 24 24" style="width: 39px" xml:space="preserve" class=""><g><path d="m16.2 15.2-3.5 3.5c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-3.5-3.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l1.8 1.8V3c0-.5.5-1 1-1s1 .5 1 1v12.6l1.8-1.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4zM20 22H4c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1z" fill="currentColor" opacity="1" data-original="#000000" class=""/></g></svg></div>');
    $('#scroll-up, #scroll-down').hide();
}


export function appendCollapseAllButton() {
    $('body').append('<div id="collapse-all" class="scroll-btn"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" viewBox="0 0 128 128" style="width: 39px" xml:space="preserve" ><g><path xmlns="http://www.w3.org/2000/svg" id="" d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z" fill="currentColor" data-original="#000000" class=""></path></g></svg></div>');
    $('#collapse-all').hide();
}


export function hideCollapseButton() {
    $('#collapse-all').hide();
}


export function collapseAll() {
    $('.transform').each(function (element) {
        const targetId = $(this).attr('data-bs-target');
        const targetElement = $(targetId);
        if (targetElement.hasClass('show')) {
            $(this).click();  // Эмулируем клик по кнопке для закрытия
        }
    });
    $(this).hide()
}

export function generateModalHTML(modalTitle, modalBody, modalFooter, modalId, modalCSS, modalAttrs) {

    const modalTitleHTML = modalTitle ? `<strong>${modalTitle}</strong>` : "";
    const modalFooterHTML = modalFooter ? `<div class="modal-footer">${modalFooter}</div>` : "";

    const id = modalId ? modalId : "modal_" + makeId();


    return `<div class="modal" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}"
     aria-hidden="true" ${modalAttrs}>
    <div class="modal-dialog modal-dialog-centered ${modalCSS}" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">${modalTitleHTML}</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
               ${modalBody}
            </div>
             ${modalFooterHTML}
        </div>
    </div>
</div>`
}
