import {headerConfig} from "./config";
import {getCssVariableValue} from "./scss_variables";
import {checkForCollapsibles} from "./utils";
import {appendCollapseAllButton, appendScrollButtons, hideCollapseButton} from "./dom-manipulation";


function hideNavContainer(classNameA, classNameB) {
    const color = getCssVariableValue('--color-content-link');

    $(classNameA).css('transform', 'scaleY(0)') // '.profile-dropdown-container'
    $(classNameA).css('top', '10px')
    $(classNameB).css('color', color)
}

function showNavContainer(classNameA, classNameB) {
    const color = getCssVariableValue('--color-content-link-hover');

    $(classNameA).css('transform', 'scaleY(1)') //
    $(classNameA).css('top', '20px')
    $(classNameB).css('color', color)
}

function hideNavContainerN(classNameA, classNameB) {

    $(classNameA).removeClass('visible-element') // '.profile-dropdown-container'
    $(classNameA).addClass('hidden-element') // '.profile-dropdown-container'
    $(classNameA).css('top', '10px')
    $(classNameB).css('color', '#ffffff')
}

function showNavContainerN(classNameA, classNameB) {

    $(classNameA).removeClass('hidden-element')
    $(classNameA).addClass('visible-element') //
    $(classNameA).css('top', '20px')
    $(classNameB).css('color', '#aebee7')
}


export function handleScrollButtons() {

    appendScrollButtons()

    function hideButtons() {
        $('#scroll-up, #scroll-down').hide();
    }

    let lastScrollTop = 0;
    let scrollTimeout;

    const debounceThreshold = 71;
    let lastDirection = null;

    // Listen to the scroll event
    $(window).scroll(function () {
        // Get current scroll top position
        let currentScrollTop = $(this).scrollTop();

        clearTimeout(scrollTimeout);


        let windowHeight = $(window).height();
        let documentHeight = $(document).height();

        let currentDirection = currentScrollTop > lastScrollTop ? 'down' : 'up';

        // Проверяем, если направление изменилось и пройден порог дебаунса
        if (Math.abs(currentScrollTop - lastScrollTop) > debounceThreshold) {
            if (currentDirection === 'down') {
                // Скроллим вниз - показываем кнопку "вверх"
                if (currentScrollTop + windowHeight < documentHeight) {
                    $('#scroll-up').hide(); // Скрыть кнопку "вверх"
                    $('#scroll-down').show(); // Показать кнопку "вниз"
                }
            } else if (currentDirection === 'up') {
                // Скроллим вверх - показываем кнопку "вниз"
                if (currentScrollTop > 0) {
                    $('#scroll-down').hide(); // Скрыть кнопку "вниз"
                    $('#scroll-up').show(); // Показать кнопку "вверх"
                }
            }

            // Обновляем последнее направление
            lastDirection = currentDirection;
        }

        if (currentScrollTop === 0) {
            $('#scroll-up').hide();
        }

        // Проверяем, находимся ли мы в самом конце страницы
        if (currentScrollTop + windowHeight >= documentHeight) {
            $('#scroll-down').hide();
        }

        scrollTimeout = setTimeout(hideButtons, 1600);

        // Set the last scroll position to the current scroll position
        lastScrollTop = currentScrollTop;

    });
    $('#scroll-up').click(function () {
        $('html, body').animate({scrollTop: 0}, 200);
        return false;
    });

    $('#scroll-down').click(function () {
        $('html, body').animate({scrollTop: $(document).height()}, 200);
        return false;
    });

}


export function handleCollapseAllButton() {
    appendCollapseAllButton()
    let lastScrollTop = 0;
    let scrollTimeout;

    const debounceThreshold = 71;

    // Listen to the scroll event
    $(window).scroll(function () {
        // Get current scroll top position
        let currentScrollTop = $(this).scrollTop();

        clearTimeout(scrollTimeout);

        // Проверяем, если направление изменилось и пройден порог дебаунса
        if (Math.abs(currentScrollTop - lastScrollTop) > debounceThreshold) {

            if (checkForCollapsibles()) {
                $('#collapse-all').fadeIn(300); // Показать кнопку, если есть элементы
            } else {
                $('#collapse-all').fadeOut(300); // Скрыть кнопку, если нет элементов
            }

        }

        scrollTimeout = setTimeout(hideCollapseButton, 1600);

        // Set the last scroll position to the current scroll position
        lastScrollTop = currentScrollTop;


    });
}

function handleVideoModal() {
    document.querySelectorAll('.videomodal').forEach(modal => {
        // Добавляем обработчик события закрытия для каждого модального окна
        modal.addEventListener('hidden.bs.modal', function () {
            // Находим видео внутри текущего модального окна
            const video = modal.querySelector('video');

            // Если видео найдено, ставим его на паузу и сбрасываем время
            if (video) {
                video.pause();
            }
        });
    });

    document.querySelectorAll('.video-container').forEach(container => {
        const video = container.querySelector('video');
        const poster = container.querySelector('.video-poster');

        // Обработка клика на постере
        poster.addEventListener('click', () => {
            // Проверяем, что видео на паузе и постер виден
            if (video.paused && !container.classList.contains('playing')) {
                container.classList.add('playing');
                video.play();
            }
        });

        // Скрытие постера при воспроизведении
        video.addEventListener('play', () => {
            container.classList.add('playing');
        });

        // Возврат к постеру при остановке
        video.addEventListener('pause', () => {
            if (video.currentTime === 0 || video.ended) {
                container.classList.remove('playing');
            }
        });

        video.addEventListener('ended', () => {
            container.classList.remove('playing');
        });
    });

}


export function handleEvents() {

    let lastScrollTop = 0;
    const body = $('body');

    // Listen to the scroll event
    $(window).scroll(function () {
        // Get current scroll top position
        let currentScrollTop = $(this).scrollTop();

        if (currentScrollTop > lastScrollTop) {
            // Scrolling down
            $('.app-header:not(.app-header-absolute):not(.app-header-sticky)').css('top', '-72px')
        } else if (currentScrollTop < lastScrollTop) {
            $('.app-header:not(.app-header-absolute):not(.app-header-sticky)').css('top', '0')
        }

        lastScrollTop = currentScrollTop;


    });


    body.on("mouseover", '.header-profile-nav-container', function (event) {
        clearTimeout(headerConfig.navMenuTimeout)
        showNavContainer('.profile-dropdown-container', '.profile-nav-link')

    })


    body.on("mouseover", '.profile-dropdown-container', function (event) {
        clearTimeout(headerConfig.navMenuTimeout)
        showNavContainer('.profile-dropdown-container', '.profile-nav-link')

    })


    body.on("mouseout", '.header-profile-nav-container', function (event) {
        headerConfig.navMenuTimeout = setTimeout(() => hideNavContainer('.profile-dropdown-container', '.profile-nav-link'), 1000)

    })
    body.on("mouseout", '.profile-dropdown-container', function (event) {
        headerConfig.navMenuTimeout = setTimeout(() => hideNavContainer('.profile-dropdown-container', '.profile-nav-link'), 1000)

    })

    body.on("mouseover", '.language-nav-container,.language-dropdown-container', function (event) {

        clearTimeout(headerConfig.langMenuTimeout)
        showNavContainer('.language-dropdown-container', '.language-nav-text')

    })


    body.on("mouseout", '.language-nav-container', function (event) {
        headerConfig.langMenuTimeout = setTimeout(() => hideNavContainer('.language-dropdown-container', '.language-nav-text'), 1000)

    })
    body.on("mouseout", '.language-dropdown-container', function (event) {
        headerConfig.langMenuTimeout = setTimeout(() => hideNavContainer('.language-dropdown-container', '.language-nav-text'), 1000)

    })

    body.on("click", '.nav_burger_container', function (event) {
        $('.nav_burger, .profile-mobile-container').toggleClass('active')
    })

    body.on('show.bs.collapse', function (event) {
        const accordionElem = $(event.target);
        const buttonElem = accordionElem.closest('.group-answer-row').find('.icon-btn-0');
        buttonElem.addClass('transform');
        buttonElem.closest('.group-answer-row').addClass('mb-5');
    });

    body.on('hide.bs.collapse', function (event) {
        const accordionElem = $(event.target);
        const buttonElem = accordionElem.closest('.group-answer-row').find('.icon-btn-0');
        buttonElem.removeClass('transform');
        buttonElem.closest('.group-answer-row').removeClass('mb-5');
    });

    handleVideoModal();
}

