const PORT = location.port ? `:${location.port}` : ''
const base = location.protocol + '//' + location.hostname + PORT + '/';
const api = 'api/v1/'
const auth = 'auth/'


const urls = {
    base: base,
    getAds: base + api + 'inventories/list/',
    bot: base + api + 'bots/fake/',
    botCreate: base + api + 'bots/create/',
    botSwitch: base + api + 'bots/switch/',
    botCopy: base + api + 'bots/copy/',
    botActions: base + api + 'bots/actions/',
    botTemplates: base + api + 'bots/templates/',
    botGet: base + api + 'bots/bot/',
    templateGet: base + api + 'bots/template/',
    botUpdate: base + 'bots/update/',
    connections: base + api + 'connections/all/',
    connect: base + api + 'connections/connect/',
    disconnect: base + api + 'connections/disconnect/',
    botConnections: base + api + 'connections/bot/',
    disconnectTelegram: base + api + 'settings/disconnect_telegram/',
    notificationsSettings: base + api + 'settings/notifications/',
    setNewPassword: base + api + 'settings/set_password/',
    setBotGuide: base + api + 'settings/set_bot_guide/',
    setBotVideoTutorial: base + api + 'settings/set_bots_video/',
    setAccountsGuide: base + api + 'settings/set_accounts_guide/',
    botSimple: base + api + 'bots/simple/',
    accounts: base + api + 'olx-accounts/list/',
    accountSwitch: base + api + 'olx-accounts/switch/',
    accountsPage: base + '/olx-accounts/',
    category: base + api + 'inventories/categories/',
    regions: base + api + 'inventories/regions/',
    cities: base + api + 'inventories/cities_list/',
    districts: base + api + 'inventories/districts/',
    currencies: base + api + 'inventories/currencies/',
    threads: base + api + 'threads/get/',
    filteredThreads: base + api + 'threads/filter/',
    newThreads: base + api + 'threads/new/',
    sendMessage: base + api + 'threads/send/',
    getMessages: base + api + 'threads/messages/',
    markThread: base + api + 'threads/mark/',
    actThread: base + api + 'threads/action/',
    getPackages: base + api + 'billing/packages/',
    getProviders: base + api + 'billing/get_providers/',
    purchasePackage: base + api + 'billing/purchase_package/',
    refillBalance: base + api + 'billing/refill_balance/',
    changeEmail: base + api + auth + 'users/me/',
    changePassword: base + api + auth + 'users/set_password/',
};

export default urls;